import {
    setCookie,
    initializeMap,
    initMenuTrigger,
    initOpenInNewTab,
    aosConfig,
    addScollClass,
    addFancyboxFixForGallery,
    initGallerySlider,
    addCloseCookies,
    initForm,
    googleMapInit,
    initLoadNews,
    faqService,

} from './functions';


//import gsap from "gsap";


const mainApp = () => {




    var lazyImages = [].slice.call(document.querySelectorAll("img[data-src]"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        }, {
            rootMargin: '150px 0px'
        });

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to event handlers here
    }

    // init menu trigger
    initMenuTrigger();

    // init open in new tab
    initOpenInNewTab();



    // add scroll class to body  
    addScollClass('50px', 'scroll');



    //fix gallery id for slideshow
    addFancyboxFixForGallery('.wp-block-gallery');

    //gallerySlider
    initGallerySlider();

    // add close function to cookies
    addCloseCookies();



    //start - contact form
    initForm();

    // googleMapInit();


    //  initLoadNews();

    faqService();


}

document.addEventListener("DOMContentLoaded", mainApp, false);


(function ($) {
    const mainAppWithJquery = () => {



        //rotator and sliders
        if ($('.indexC3__slider').length > 0) {
            $('.indexC3__slider').slick({
                infinite: true,
                arrows: true,
                dots: false,
                autoplay: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                speed: 500,
                autoplaySpeed: 10000,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        }




        //fancybox
        const fcbChck = document.querySelectorAll(".blocks-gallery-item a, .fancybox, .wp-block-image a[href$='.jpg'], .wp-block-image a[href$='.jpeg'], .wp-block-image a[href$='.png']");

        if (fcbChck.length > 0) {
            let scriptF = document.createElement("script");
            scriptF.setAttribute("src", window.location.origin + "/wp-content/themes/advance/plugins/fancybox/jquery.fancybox.min.js");
            document.body.appendChild(scriptF);
            let cssF = document.createElement("link");
            cssF.setAttribute("rel", 'stylesheet');
            cssF.setAttribute("href", window.location.origin + "/wp-content/themes/advance/plugins/fancybox/jquery.fancybox.min.css");
            document.body.appendChild(cssF);

            scriptF.addEventListener("load", () => {

                $(".blocks-gallery-item a, .fancybox, .wp-block-image a[href$='.jpg'], .wp-block-image a[href$='.jpeg'], .wp-block-image a[href$='.png']").fancybox({
                    'loop': true,
                    'margin': [44, 0],
                    'gutter': 50,
                    'keyboard': true,
                    'arrows': true,
                    'infobar': false,
                    'toolbar': true,
                    'buttons': ["slideShow", "fullScreen", "close"],
                    'idleTime': 4,
                    'protect': false,
                    'modal': false,
                    'animationEffect': "fade",
                    'animationDuration': 600,
                    'transitionEffect': "fade",
                    'transitionDuration': 800,
                    backFocus: false,
                    thumbs: {
                        autoStart: true, // Display thumbnails on opening
                        hideOnClose: true, // Hide thumbnail grid when closing animation starts
                        parentEl: ".fancybox-container", // Container is injected into this element
                        axis: "y" // Vertical (y) or horizontal (x) scrolling
                    }
                });
            });

        }


        $('body *[href*="*!*"]').each(function () {
            $(this).attr('href', $(this).attr('href').replace('*!*', '@'));
        });

        $('body').find(":contains('*!*'):not(:has(*))").each(function () {
            $(this).html($(this).html().replace(/\*!\*/gim, '@'));
        });

        // init AOS animation config
        aosConfig();

    };
    document.addEventListener("DOMContentLoaded", mainAppWithJquery, false);


    /* let rating = document.querySelector('.indexHeader__reviewsContent');
     if (rating) {
         const formData = new FormData();
         formData.append('action', 'reviews');
         fetch(ajaxParams.ajaxurl, {
             method: "post",
             body: formData
         })
             .then(res => res.text())
             .then(res => {
                 rating.innerHTML = res;
 
             })
     }*/

    /*if ($('.rank-math-schema-pro').length > 0 && $('.indexHeader__reviewsContent').length > 0) {
        let rankSource = $('.rank-math-schema-pro').html();
        rankSource = JSON.parse(rankSource);
        console.log(rankSource['@graph']);
        if (rankSource['@graph']) {
            rankSource['@graph'].forEach((graph) => {
                if (graph['@type'] == 'Product') {
                    console.log(graph.aggregateRating);
                }
            });
        }
    }*/


})(jQuery);



